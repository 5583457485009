import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import {
  CurrencyPairDetail,
  CurrencyShortName,
  SupportedCurrencyPairs,
} from "@gemini-common/scripts/constants/currencies";
import { InnerNotificationData } from "@gemini-ui/components/Notifications/constants";
import { PromoType } from "@gemini-ui/components/PromoModals/constants";
import { HistoricalPrices, NotificationSettings } from "@gemini-ui/constants";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { HistoryEntry } from "@gemini-ui/constants/custody";
import {
  LimitOrder,
  MarketOrder,
  OpenPosition,
  Order,
  PerpetualAccountRisk,
  Side,
  TradeHistoryOrder,
} from "@gemini-ui/constants/orders";
import { HourlyFundingTransfer, PositionTransfer, RealizedPnL } from "@gemini-ui/constants/perpEvents";
import { TemplateProps } from "@gemini-ui/constants/templateProps";
import {
  AccountAdvanceType,
  GeminiAccount,
  GeminiEntity,
  Subaccounts,
} from "@gemini-ui/constants/templateProps/account";
import { Colors } from "@gemini-ui/design-system";
import { AccountWebSocketState } from "@gemini-ui/pages/ActiveTrader/Spot/api/ws/account";
import { ChartState, ChartStates } from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart/constants";
import { WebsocketTrade, WebsocketTradeWithDirection } from "@gemini-ui/pages/ActiveTrader/Spot/MarketTrades/constants";
import { Liquidation } from "@gemini-ui/pages/ActiveTrader/Spot/MyOrders/LiquidationsTable";
import { PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { Fee } from "@gemini-ui/services/trading/types";
import { UserPermissionsState } from "@gemini-ui/services/user/constants";

export type CandleInterval =
  | "candles_1m"
  | "candles_5m"
  | "candles_15m"
  | "candles_30m"
  | "candles_1h"
  | "candles_6h"
  | "candles_1d";

type CandlesPeriodicity = {
  [period in CandleInterval]: {
    interval: number;
    timeUnit: "minute" | "hour" | "day";
  };
};

export const CANDLE_PERIODICITIES: CandlesPeriodicity = {
  candles_1m: { interval: 1, timeUnit: "minute" },
  candles_5m: { interval: 5, timeUnit: "minute" },
  candles_15m: { interval: 15, timeUnit: "minute" },
  candles_30m: { interval: 30, timeUnit: "minute" },
  candles_1h: { interval: 1, timeUnit: "hour" },
  candles_6h: { interval: 6, timeUnit: "hour" },
  candles_1d: { interval: 1, timeUnit: "day" },
} as const;

export const TVChartResolutionMap = {
  candles_1m: 1,
  candles_5m: 5,
  candles_15m: 15,
  candles_30m: 30,
  candles_1h: 60,
  candles_6h: 360,
  candles_1d: "1D",
};

export const ResolutionsToKlinesMap = {
  1: "1m",
  5: "5m",
  15: "15m",
  30: "30m",
  60: "1hr",
  360: "6hr",
  "1D": "1day",
} as const;

export type ChartType =
  | "candle"
  | "line"
  | "hollow_candle"
  | "step_mountain_marketdepth"
  | "area"
  | "baseline"
  | "high-low"
  | "bars"
  | "heiken-ashi";
const CANDLE: ChartType = "candle";
const LINE: ChartType = "line";
const HOLLOW_CANDLE: ChartType = "hollow_candle";
const AREA: ChartType = "area";
const BASELINE: ChartType = "baseline";
const BARS: ChartType = "bars";
const HEIKEN_ASHI: ChartType = "heiken-ashi";
const HIGH_LOW: ChartType = "high-low";

const DEPTH: ChartType = "step_mountain_marketdepth";

export const ChartTypes = {
  CANDLE,
  LINE,
  HOLLOW_CANDLE,
  DEPTH,
  AREA,
  BASELINE,
  HIGH_LOW,
  BARS,
  HEIKEN_ASHI,
};

export const StaticChartConfig = {
  [ChartStates.TRADING_VIEW]: {
    supportedChartType: [CANDLE, LINE, HOLLOW_CANDLE, DEPTH, AREA, BASELINE, HIGH_LOW, BARS, HEIKEN_ASHI],
  },
};

export const tradingViewChartTypeIdMap: Record<ChartType, number> = {
  candle: 1,
  line: 2,
  hollow_candle: 9,
  step_mountain_marketdepth: 11,
  area: 3,
  baseline: 10,
  "high-low": 12,
  bars: 0,
  "heiken-ashi": 8,
};

export type Candle = [number, number, number, number, number, number];

export type OrderBookChanges = [Side, string, string][];

export interface FormattedOrderBookChange {
  side: Side;
  price: number;
  quantity: number;
}

export interface FormattedOrderBook {
  asks: FormattedOrderBookChange[];
  bids: FormattedOrderBookChange[];
}

export type ChartConfig = {
  [ChartStates.TRADING_VIEW]: {
    chartType: ChartType;
    candleInterval: CandleInterval;
    showOpenOrders: boolean;
    showRecentlyFilledOrders: boolean;
  };
};

export type OrderFormUnDockedSettings = {
  primaryOrderFormVisible: boolean;
  secondaryOrderFormVisible: boolean;
  showOrderFormCoachMark: boolean;
};

export type ChartNotifications = {
  showChartOpenOrderLimitMessage: boolean;
};

export type PairKeyedChartConfig = {
  [x in SupportedCurrencyPairs]: ChartConfig;
};

export type PairKeyedOrderBook = {
  [x in SupportedCurrencyPairs]: FormattedOrderBook;
};

export type PairKeyedLastPrice = {
  [x in SupportedCurrencyPairs]: string;
};

export type PairKeyedCandleData = {
  [x in SupportedCurrencyPairs]: Candle[];
};

export type PairKeyedTradeData = {
  [x in SupportedCurrencyPairs]: WebsocketTradeWithDirection[];
};

export type PairKeyedRiskStats = {
  [x in SupportedCurrencyPairs]: PerpetualAccountRisk;
};

export type PairKeyedHistoricalPrices = {
  [x in SupportedCurrencyPairs]: HistoricalPrices;
};

export type PairKeyedFormattedOrderBookChanges = {
  [x in SupportedCurrencyPairs]: FormattedOrderBookChange[];
};

export type PairKeyedMarkPriceData = {
  [x in SupportedCurrencyPairs]: MarkPriceData;
};

export type PairKeyedFundingAmountData = {
  [x in SupportedCurrencyPairs]: FundingAmountData;
};

export type PairKeyedChartState = {
  [x in SupportedCurrencyPairs]: ChartState;
} & { default: ChartState };

export type PairKeyedMarginStatsError = {
  [x in SupportedCurrencyPairs]: boolean;
};

export type PairKeyedFees = {
  [x in SupportedCurrencyPairs]: Fee & { error?: string; loading?: boolean };
};

export enum FilterType {
  open = "open",
  completed = "completed",
  positions = "positions",
  tradeHistory = "tradeHistory",
  liquidation = "liquidation",
  funding = "funding",
  transfers = "transfers",
  realizedPl = "realizedPl",
  balances = "balances",
}

export type SortDirection = "asc" | "desc";
export const SortDirections: Record<Uppercase<SortDirection>, SortDirection> = {
  ASC: "asc",
  DESC: "desc",
};

export interface TradeFilter {
  pair?: SupportedCurrencyPairs[];
  currency?: CurrencyShortName[];
  side?: Side[];
}

interface NotificationSettingsForm {
  values: {
    emailOnDigitalAssetAction?: boolean;
    confirmCancelOrders?: boolean;
    confirmLimitOrders?: boolean;
    confirmMarketOrders?: boolean;
    emailOnMarketing?: boolean;
    emailOnCompletedMarketOrder?: boolean;
    emailOnCompletedInstantOrder?: boolean;
    emailOnNewOrder?: boolean;
    emailOnBankAction?: boolean;
    emailOnCompletedLimitOrder?: boolean;
    emailOnRecurringOrderActivity?: boolean;
  };
  errors?: any;
  csrfToken: string;
}

export interface CollateralSettings {
  enableCrossCollateral: boolean;
  enableNegativeBalances: boolean;
  negativeBalance: boolean;
}

export type ConnectionStatus = "connected" | "disconnected" | "connecting";
export const CONNECTION_STATUS: Record<ConnectionStatus, ConnectionStatus> = {
  connected: "connected",
  disconnected: "disconnected",
  connecting: "connecting",
} as const;

export type BalancesData = {
  balances: CurrencyBalances;
  loading: boolean;
  error: string;
};

export type OrdersState = {
  loading?: boolean;
  error?: boolean;
  openOrders?: Order[];
};

export type HistoricalTradesState = {
  loading?: boolean;
  error?: boolean;
  trades?: Order[];
};

interface BlotterApiBase {
  loading?: boolean;
  error?: string | null;
}

export interface FundingState extends BlotterApiBase {
  data: HourlyFundingTransfer[];
}

export interface LiquidationState extends BlotterApiBase {
  data: Liquidation[];
}

export interface RealizedPlState extends BlotterApiBase {
  data: RealizedPnL[];
}

export interface OrderHistoryState extends BlotterApiBase {
  data?: Order[];
  initialLoading?: boolean;
}

export interface TradeHistoryState extends BlotterApiBase {
  data?: TradeHistoryOrder[];
  initialLoading?: boolean;
}

export type ApiData = {
  completed: OrderHistoryState;
  funding: FundingState;
  liquidation: LiquidationState;
  realizedPl: RealizedPlState;
  tradeHistory: TradeHistoryState;
};

export interface TradeState {
  permissions: UserPermissionsState;
  EuropeAgreementsEnabled?: boolean;
  account?: GeminiAccount;
  accountWS: AccountWebSocketState;
  allPairDetails: {
    data?: PairDetail[];
    loading?: boolean;
    error?: boolean;
  };
  balancesData: BalancesData;
  canCancelOrder?: boolean;
  candleData: PairKeyedCandleData;
  chartState?: PairKeyedChartState;
  chartConfig?: PairKeyedChartConfig;
  orderFormUnDockedSettings?: OrderFormUnDockedSettings;
  chartNotifications?: ChartNotifications;
  collateralSettings: CollateralSettings;
  connectionStatus: ConnectionStatus;
  countryCode: CountryAbbreviation;
  displayCryptoAssetNotice?: boolean;
  fees: PairKeyedFees;
  gcTradingEnabled: boolean;
  historicalPrices: PairKeyedHistoricalPrices;
  isSignedIn: boolean;
  isSandbox?: boolean;
  refreshCount: number;
  marginStatsError: PairKeyedMarginStatsError;
  notificationSettings: NotificationSettings;
  notificationSettingsForm?: NotificationSettingsForm;
  notifications: InnerNotificationData[];
  orders: OrdersState;
  historicalTrades: HistoricalTradesState;
  orderBookChanges: PairKeyedOrderBook;
  orderBookData: PairKeyedOrderBook;
  orderDataFilter: TradeFilter;
  promoModalsToShow: PromoType[];
  riskStats: PairKeyedRiskStats;
  subaccountHashid: string;
  subaccounts: Subaccounts;
  supportedPairs?: CurrencyPairDetail[];
  templateProps?: TemplateProps;
  tradeData: PairKeyedTradeData;
  trades?: LimitOrder[];
  websocketBaseUrl?: string;
  websocketLastPrice: PairKeyedLastPrice;
  derivatives: boolean;
  positions: {
    data?: OpenPosition[];
    loading?: boolean;
    error?: boolean;
  };
  markPriceData: PairKeyedMarkPriceData;
  fundingAmountData: PairKeyedFundingAmountData;
  liquidationData: (LimitOrder | MarketOrder | PositionTransfer)[];
  geminiEntity: GeminiEntity;
  isDualMarket: boolean;
  watchlist: string[];
  transferHistory: HistoryEntry[];
  wsHeartbeat: number;
  apiData: ApiData;
  showLayoutModals?: boolean;
  showLayoutCoachMark?: boolean;
}

export const MARKETDATA_WS_MESSAGES = {
  L2_UPDATES: "l2_updates",
  TRADE: "trade",
  CANDLE_UPDATES: "candle_updates",
  MARK_PRICE_UPDATES: "mark_price_updates",
  FUNDING_AMOUNT_UPDATES: "funding_amount_updates",
  HEARTBEAT: "heartbeat",
} as const;

type WSL2Update = {
  type: typeof MARKETDATA_WS_MESSAGES.L2_UPDATES;
  symbol: SupportedCurrencyPairs;
} & OrderBookDataUpdate;

type WSTrade = {
  type: typeof MARKETDATA_WS_MESSAGES.TRADE;
} & WebsocketTrade;

type WSCandleUpdate = {
  type: typeof MARKETDATA_WS_MESSAGES.CANDLE_UPDATES;
  changes: Candle[];
  symbol: SupportedCurrencyPairs;
};

export enum WSErrorReason {
  NoValidTradingPairs = "NoValidTradingPairs",
}

export type WSError = {
  result: "error";
  reason: WSErrorReason;
};

export type MarkPriceData = {
  mark_price: string;
  spot_index: string;
  timestamp: number;
};

export type FundingAmountData = {
  funding_amount: string;
  funding_date_time: number;
  funding_interval_in_minutes: number;
  is_realized: boolean;
  timestamp: number;
};

export type WSMarkPriceUpdate = {
  type: typeof MARKETDATA_WS_MESSAGES.MARK_PRICE_UPDATES;
  changes: MarkPriceData;
  symbol: SupportedCurrencyPairs;
};

export type WSFundingAmountUpdate = {
  type: typeof MARKETDATA_WS_MESSAGES.FUNDING_AMOUNT_UPDATES;
  changes: FundingAmountData;
  symbol: SupportedCurrencyPairs;
};

export type WSHeartbeatUpdate = {
  type: typeof MARKETDATA_WS_MESSAGES.HEARTBEAT;
  timestamp: number;
};

export type WebsocketMessages =
  | WSL2Update
  | WSTrade
  | WSCandleUpdate
  | WSMarkPriceUpdate
  | WSFundingAmountUpdate
  | WSHeartbeatUpdate;

export interface OrderBookDataUpdate {
  changes: OrderBookChanges;
  trades: WebsocketTrade[];
  symbol: SupportedCurrencyPairs;
}

// This essentially throttles orderbook rendering
export const FLUSH_ORDER_BOOK_CHANGES_INTERVAL = 300;

export const PERP_COLORS = {
  BORDER_GRAY: "#4d5359",
  HEADER_GRAY: Colors.gray[400],
  COLUMN_GRAY: "#BFBFC6",
  BUY: "#1DA450",
  SELL: "#E74C3B",
};

export const ADL_METER_COLORS = {
  GREEN: Colors.green[500],
  YELLOW: Colors.yellow[500],
  ORANGE: Colors.orange[500],
  RED: Colors.red[500],
};

const WS_CANDLE_EVENTS: { [k in CandleInterval]: CandleInterval } = {
  candles_1m: "candles_1m",
  candles_5m: "candles_5m",
  candles_15m: "candles_15m",
  candles_30m: "candles_30m",
  candles_1h: "candles_1h",
  candles_6h: "candles_6h",
  candles_1d: "candles_1d",
} as const;

export const WS_EVENTS = {
  l2: "l2",
  mark_price: "mark_price",
  funding_amount: "funding_amount",
  ...WS_CANDLE_EVENTS,
} as const;

export type WebsocketSendEvents = (typeof WS_EVENTS)[keyof typeof WS_EVENTS];

export const PERPETUAL_SWAPS_WS_EVENTS = [WS_EVENTS.mark_price, WS_EVENTS.funding_amount] as const;
export const PERPETUAL_PAIRS_WS_MAPPING = {
  BTCGUSDPERP: "BTCGUSDPERP",
  ETHGUSDPERP: "ETHGUSDPERP",
  PEPEGUSDPERP: "PEPEGUSDPERP",
  MATICGUSDPERP: "MATICGUSDPERP",
  SOLGUSDPERP: "SOLGUSDPERP",
  XRPGUSDPERP: "XRPGUSDPERP",
  AVAXGUSDPERP: "AVAXGUSDPERP",
  DOGEGUSDPERP: "DOGEGUSDPERP",
  DOTGUSDPERP: "DOTGUSDPERP",
  INJGUSDPERP: "INJGUSDPERP",
  LINKGUSDPERP: "LINKGUSDPERP",
  LTCGUSDPERP: "LTCGUSDPERP",
  BNBGUSDPERP: "BNBGUSDPERP",
  ADAGUSDPERP: "ADAGUSDPERP",
  WIFGUSDPERP: "WIFGUSDPERP",
  OPGUSDPERP: "OPGUSDPERP",
  BONKGUSDPERP: "BONKGUSDPERP",
  POPCATGUSDPERP: "POPCATGUSDPERP",
};

export const LoggedOutAccountFixture: GeminiAccount = {
  geminiEntity: "GeminiDefault",
  defaultFiat: "USD",
  supportedFiat: ["USD", "CAD", "JPY", "EUR", "GBP", "AUD", "SGD"],
  supportedCrypto: [],
  supportedExchangeCrypto: [],
  supportedPairs: [],
  supportedForWrap: {},
  supportedForUnwrap: {},
  achAdvanceStatus: AccountAdvanceType.ADVANCE,
};
