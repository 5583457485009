import { Action } from "@reduxjs/toolkit";
import { CurrencyPairDetail } from "@gemini-common/scripts/constants/currencies";
import { NotificationProps } from "@gemini-ui/components/Notifications/Notification";
import { NotificationSettingKey } from "@gemini-ui/constants";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { HistoryEntry } from "@gemini-ui/constants/custody";
import { Order, PerpetualAccountRisk, TradeHistoryOrder } from "@gemini-ui/constants/orders";
import { AccountWebsocketServerMessage } from "@gemini-ui/pages/ActiveTrader/Spot/api/ws/account";
import { OrdersChannelWebSocketMessage } from "@gemini-ui/pages/ActiveTrader/Spot/api/ws/orders";
import { ChartState } from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart/constants";
import {
  CandleInterval,
  ChartType,
  CollateralSettings,
  FundingState,
  LiquidationState,
  RealizedPlState,
  TradeFilter,
  WebsocketMessages,
  WebsocketSendEvents,
} from "@gemini-ui/pages/ActiveTrader/Spot/constants";
import { PairDetail } from "@gemini-ui/pages/RetailTrade/constants";
import { FeeRates } from "@gemini-ui/services/trading/types";
import { UserPermissionsState } from "@gemini-ui/services/user/constants";

export const UPDATE_BALANCES = "trade/UPDATE_BALANCES";
interface UpdateBalances extends Action {
  type: typeof UPDATE_BALANCES;
  balances: CurrencyBalances;
  error: string;
}

export const UPDATE_USER_PERMISSIONS = "trade/UPDATE_USER_PERMISSIONS";
interface UpdateUserPermissions extends Action {
  type: typeof UPDATE_USER_PERMISSIONS;
  payload: UserPermissionsState;
}

export const ADD_NOTIFICATION = "trade/ADD_NOTIFICATION";
interface AddNotification extends Action {
  type: typeof ADD_NOTIFICATION;
  notification: NotificationProps;
}
export const REMOVE_NOTIFICATION = "trade/REMOVE_NOTIFICATION";
interface RemoveNotification extends Action {
  type: typeof REMOVE_NOTIFICATION;
  key: string;
}

export const UPDATE_CANDLE_INTERVAL = "trade/UPDATE_CANDLE_INTERVAL";
interface UpdateCandleInterval extends Action {
  type: typeof UPDATE_CANDLE_INTERVAL;
  payload: {
    interval: CandleInterval;
    chartState: ChartState;
    tradingPairSymbol: string;
  };
}
export const UPDATE_CHART_STATE = "trade/UPDATE_CHART_STATE";
interface UpdateChartState extends Action {
  type: typeof UPDATE_CHART_STATE;
  chartState: ChartState;
  tradingPairSymbol: string;
}

export const UPDATE_CHART_TYPE = "trade/UPDATE_CHART_TYPE";
interface UpdateChartType extends Action {
  type: typeof UPDATE_CHART_TYPE;
  payload: {
    chartType: ChartType;
    chartState: ChartState;
    tradingPairSymbol: string;
  };
}

export const UPDATE_ORDER_DATA_FILTER = "trade/UPDATE_ORDER_DATA_FILTER";
interface UpdateOrderDataFilter extends Action {
  type: typeof UPDATE_ORDER_DATA_FILTER;
  filter: Partial<TradeFilter>;
}

export const REMOVE_ORDER = "trade/REMOVE_ORDER";
interface RemoveOrder extends Action {
  type: typeof REMOVE_ORDER;
  order: Order;
}
export const REMOVE_ORDERS = "trade/REMOVE_ORDERS";
interface RemoveOrders extends Action {
  type: typeof REMOVE_ORDERS;
}

export const UPDATE_API_ORDER_HISTORY = "trade/UPDATE_API_ORDER_HISTORY";
interface UpdateApiOrderHistory extends Action {
  type: typeof UPDATE_API_ORDER_HISTORY;
  payload: { data?: Order[]; loading?: boolean; error?: string | null; initialLoading?: boolean };
}

export const UPDATE_API_TRADE_HISTORY = "trade/UPDATE_API_TRADE_HISTORY";
interface UpdateApiTradeHistory extends Action {
  type: typeof UPDATE_API_TRADE_HISTORY;
  payload: { data?: TradeHistoryOrder[]; loading?: boolean; error?: string | null; initialLoading?: boolean };
}

export const UPDATE_API_LIQUIDATIONS = "trade/UPDATE_API_LIQUIDATIONS";
interface UpdateApiLiquidations extends Action {
  type: typeof UPDATE_API_LIQUIDATIONS;
  payload: LiquidationState;
}

export const UPDATE_API_FUNDING = "trade/UPDATE_API_FUNDING";
interface UpdateApiFunding extends Action {
  type: typeof UPDATE_API_FUNDING;
  payload: FundingState;
}

export const UPDATE_API_REALIZEDPL = "trade/UPDATE_API_REALIZEDPL";
interface UpdateApiRealizedPl extends Action {
  type: typeof UPDATE_API_REALIZEDPL;
  payload: RealizedPlState;
}

export const UPDATE_OPEN_ORDERS = "trade/UPDATE_OPEN_ORDERS";
interface UpdateOpenOrders extends Action {
  type: typeof UPDATE_OPEN_ORDERS;
  payload: { openOrders?: Order[]; error?: boolean; loading?: boolean };
}

export const UPDATE_HISTORICAL_TRADES = "trade/UPDATE_HISTORICAL_TRADES";
interface UpdateHistoricalTrades extends Action {
  type: typeof UPDATE_HISTORICAL_TRADES;
  payload: { trades?: Order[]; error?: boolean; loading?: boolean };
}

export const UPDATE_POSITIONS = "trade/UPDATE_POSITIONS";
interface UpdatePositions extends Action {
  type: typeof UPDATE_POSITIONS;
  payload: { openOrders?: Order[]; error?: boolean; loading?: boolean };
}

export const UPDATE_PAIR_DETAILS = "trade/UPDATE_PAIR_DETAILS";
interface UpdatePairDetails extends Action {
  type: typeof UPDATE_PAIR_DETAILS;
  payload: { allPairDetails?: PairDetail[]; error?: boolean; loading?: boolean };
}

export const UPDATE_SUPPORTED_PAIRS = "trade/UPDATE_SUPPORTED_PAIRS";
interface UpdateSupportedPairs extends Action {
  type: typeof UPDATE_SUPPORTED_PAIRS;
  payload: { supportedPairs: CurrencyPairDetail[] };
}

export const UPDATE_NOTIFICATION_SETTINGS = "trade/UPDATE_NOTIFICATION_SETTINGS";
interface UpdateNotificationSettings extends Action {
  type: typeof UPDATE_NOTIFICATION_SETTINGS;
  payload: {
    confirmField: NotificationSettingKey;
    hideFutureConfirms: boolean;
  };
}

export const TOGGLE_MARGIN_ONBOARDING = "trade/TOGGLE_MARGIN_ONBOARDING";
interface ToggleMarginOnboarding extends Action {
  type: typeof TOGGLE_MARGIN_ONBOARDING;
  payload: {
    showOnboarding: boolean;
  };
}

export const WEBSOCKET_CONNECT = "trade/WEBSOCKET_CONNECT";
interface WebsocketConnect extends Action {
  type: typeof WEBSOCKET_CONNECT;
}

export const WEBSOCKET_DISCONNECT = "trade/WEBSOCKET_DISCONNECT";
interface WebsocketDisconnect extends Action {
  type: typeof WEBSOCKET_DISCONNECT;
}

export type WebsocketSendData = {
  type: "subscribe" | "unsubscribe";
  subscriptions: { name: WebsocketSendEvents; symbols: string[] }[];
};

export const WEBSOCKET_SEND = "trade/WEBSOCKET_SEND" as const;
interface WebsocketSend extends Action {
  type: typeof WEBSOCKET_SEND;
  data: WebsocketSendData;
}

export const WEBSOCKET_ON_MESSAGE = "trade/WEBSOCKET_ON_MESSAGE";
type WebsocketOnMessage = {
  type: typeof WEBSOCKET_ON_MESSAGE;
  data: WebsocketMessages;
};

export const ACCOUNT_WEBSOCKET_MESSAGE = "trade/ACCOUNT_WEBSOCKET_MESSAGE";
type AccountWebsocketMessage = {
  type: typeof ACCOUNT_WEBSOCKET_MESSAGE;
  data: AccountWebsocketServerMessage;
};

export const ACCOUNT_WEBSOCKET_RECONNECT = "trade/ACCOUNT_WEBSOCKET_RECONNECT";
type AccountWebsocketReconnect = {
  type: typeof ACCOUNT_WEBSOCKET_RECONNECT;
};

export const ORDERS_CHANNEL_WEBSOCKET_MESSAGE = "trade/ORDERS_CHANNEL_WEBSOCKET_MESSAGE";
type OrdersChannelWebsocketMessage = {
  type: typeof ORDERS_CHANNEL_WEBSOCKET_MESSAGE;
  data: OrdersChannelWebSocketMessage;
};

export const WEBSOCKET_ON_OPEN = "trade/WEBSOCKET_ON_OPEN";
interface WebsocketOnOpen extends Action {
  type: typeof WEBSOCKET_ON_OPEN;
}
export const WEBSOCKET_ON_CLOSE = "trade/WEBSOCKET_ON_CLOSE";
interface WebsocketOnClose extends Action {
  type: typeof WEBSOCKET_ON_CLOSE;
}
export const WEBSOCKET_ON_CONNECTING = "trade/WEBSOCKET_ON_CONNECTING";
interface WebsocketOnConnecting extends Action {
  type: typeof WEBSOCKET_ON_CONNECTING;
}
export const FLUSH_ORDER_BOOK_CHANGES = "trade/FLUSH_ORDER_BOOK_CHANGES";
interface FlushOrderBookChanges extends Action {
  type: typeof FLUSH_ORDER_BOOK_CHANGES;
}

export const UPDATE_COLLATERAL_SETTINGS = "trade/UPDATE_COLLATERAL_SETTINGS";
interface UpdateCollateralSettings extends Action {
  type: typeof UPDATE_COLLATERAL_SETTINGS;
  payload: CollateralSettings;
}

export const UPDATE_RISK_STATS = "trade/UPDATE_RISK_STATS";
interface UpdateRiskStats extends Action {
  type: typeof UPDATE_RISK_STATS;
  payload: PerpetualAccountRisk;
  tradingPairSymbol: string;
}
export const INCREMENT_REFRESH_COUNT = "trade/INCREMENT_REFRESH_COUNT";

interface IncrementRefreshCount extends Action {
  type: typeof INCREMENT_REFRESH_COUNT;
}

export const UPDATE_MARGIN_STATS_ERROR = "trade/UPDATE_MARGIN_STATS_ERROR";
interface UpdateMarginStatsError extends Action {
  type: typeof UPDATE_MARGIN_STATS_ERROR;
  payload: boolean;
  tradingPairSymbol: string;
}

export const TOGGLE_DUAL_MARKET = "trade/TOGGLE_DUAL_MARKET";
interface ToggleDualMarket extends Action {
  type: typeof TOGGLE_DUAL_MARKET;
  payload: boolean;
}

export const UPDATE_WATCHLIST = "trade/UPDATE_WATCHLIST";
interface UpdateWatchlist extends Action {
  type: typeof UPDATE_WATCHLIST;
  payload: string[];
}

export const UPDATE_TRANSFER_HISTORY = "trade/UPDATE_TRANSFER_HISTORY";
interface UpdateTransferHistory extends Action {
  type: typeof UPDATE_TRANSFER_HISTORY;
  payload: HistoryEntry[];
}

export const INITIALIZE_PAIR = "trade/INITIALIZE_PAIR";
interface InitializePair extends Action {
  type: typeof INITIALIZE_PAIR;
  pair: string;
}

export const UPDATE_TV_CHART_CONFIG = "trade/UPDATE_TV_CHART_CONFIG";
export interface UpdateTVChartConfig extends Action {
  type: typeof UPDATE_TV_CHART_CONFIG;
  payload: {
    id: string;
    value: string | boolean;
    tradingPairSymbol: string;
  };
}

export const UPDATE_ORDER_FORM_UNDOCKED_SETTINGS = "trade/UPDATE_ORDER_FORM_UNDOCKED_SETTINGS";
export interface UpdateOrderFormUnDockedSettings extends Action {
  type: typeof UPDATE_ORDER_FORM_UNDOCKED_SETTINGS;
  payload: {
    id: string;
    value: boolean;
  };
}

export const UPDATE_CHART_NOTIFICATIONS = "trade/UPDATE_CHART_NOTIFICATIONS";
export interface UpdateChartNotifications extends Action {
  type: typeof UPDATE_CHART_NOTIFICATIONS;
  payload: {
    id: string;
    value: boolean;
  };
}

export const FETCH_FEES_START = "FETCH_FEES_START";
interface FetchFeesStart extends Action {
  type: typeof FETCH_FEES_START;
  pair: string;
}
export const FETCH_FEES_SUCCESS = "FETCH_FEES_SUCCESS";
interface FetchFeesSuccess extends Action {
  type: typeof FETCH_FEES_SUCCESS;
  feeSchedule: FeeRates<string>;
  pair: string;
}
export const FETCH_FEES_ERROR = "FETCH_FEES_ERROR";
interface FetchFeesError extends Action {
  type: typeof FETCH_FEES_ERROR;
  error: string;
  pair: string;
}

export const EMPTY_ORDER_BOOK = "EMPTY_ORDER_BOOK";

interface EmptyOrderBook extends Action {
  type: typeof EMPTY_ORDER_BOOK;
  pair: string;
}

export const UPDATE_WEBSOCKET_LAST_HEART_BEAT = "UPDATE_WEBSOCKET_LAST_HEART_BEAT";

interface UpdateWebsocketLastHeartbeat extends Action {
  type: typeof UPDATE_WEBSOCKET_LAST_HEART_BEAT;
  timestamp: number;
}

export const SET_SHOW_LAYOUT_MODALS = "trade/SET_SHOW_LAYOUT_MODALS";
interface SetShowLayoutModals extends Action {
  type: typeof SET_SHOW_LAYOUT_MODALS;
  payload: boolean;
}

export const SET_SHOW_LAYOUT_COACHMARK = "trade/SET_SHOW_LAYOUT_COACHMARK";
interface SetShowLayoutCoachMark extends Action {
  type: typeof SET_SHOW_LAYOUT_COACHMARK;
  payload: boolean;
}

export type TradeActionTypes =
  | AddNotification
  | RemoveNotification
  | UpdateCandleInterval
  | UpdateChartType
  | UpdateChartState
  | UpdateOrderDataFilter
  | WebsocketOnMessage
  | WebsocketConnect
  | WebsocketDisconnect
  | WebsocketSend
  | WebsocketOnOpen
  | WebsocketOnClose
  | WebsocketOnConnecting
  | RemoveOrders
  | UpdateOpenOrders
  | RemoveOrder
  | UpdateNotificationSettings
  | FlushOrderBookChanges
  | ToggleMarginOnboarding
  | UpdateBalances
  | UpdateCollateralSettings
  | UpdateRiskStats
  | IncrementRefreshCount
  | UpdateMarginStatsError
  | ToggleDualMarket
  | UpdateWatchlist
  | UpdateTransferHistory
  | InitializePair
  | FetchFeesStart
  | FetchFeesSuccess
  | FetchFeesError
  | EmptyOrderBook
  | UpdateUserPermissions
  | UpdatePositions
  | UpdatePairDetails
  | UpdateSupportedPairs
  | UpdateWebsocketLastHeartbeat
  | UpdateHistoricalTrades
  | UpdateTVChartConfig
  | UpdateOrderFormUnDockedSettings
  | UpdateChartNotifications
  | UpdateApiOrderHistory
  | UpdateApiTradeHistory
  | UpdateApiLiquidations
  | UpdateApiFunding
  | UpdateApiRealizedPl
  | SetShowLayoutCoachMark
  | SetShowLayoutModals
  | AccountWebsocketMessage
  | OrdersChannelWebsocketMessage
  | AccountWebsocketReconnect;
